import * as React from 'react';

import {
  Box,
  BoxProps,
  Divider,
  Grid,
  IconButton,
  Popover,
  TextField,
  Toolbar,
  Typography,
  makeStyles
} from '@material-ui/core';

import BackIcon from '@material-ui/icons/ArrowBackOutlined';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import clsx from 'clsx';

type RootSizing = Pick<BoxProps, 'flex' | 'height' | 'width'>;

interface SidebarProps extends RootSizing {
  title: string;
  actionButtons?: React.ReactElement[];
  content?: React.ReactNode;
  enableSearch?: boolean;
  onClose: () => void;
  onSearch?: (term: string) => void;
  onClearSearch?: () => void;
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    flex: '0 0 auto',
    minHeight: theme.spacing(7),
    // Default style is px 2 on xs and px 3 on sm+
    // `disableGutters` to remove and set px 2 at all breakpoints here
    padding: theme.spacing(0, 2),
    background: theme.palette.grey[300],
    color: theme.palette.getContrastText(theme.palette.grey[300])
  },
  searchToolbar: {
    background: theme.palette.grey[900],
    color: theme.palette.getContrastText(theme.palette.grey[900])
  },
  searchInput: {
    ...theme.typography.h5,
    color: 'inherit',
    '& input[type="search"]::-webkit-search-decoration': {
      '-webkit-appearance': 'none'
    },
    '& input[type="search"]::-webkit-search-cancel-button': {
      '-webkit-appearance': 'none'
    },
    '& input[type="search"]::-webkit-search-results-button': {
      '-webkit-appearance': 'none'
    },
    '& input[type="search"]::-webkit-search-results-decoration': {
      '-webkit-appearance': 'none'
    }
  },
  searchDivider: {
    backgroundColor: theme.palette.grey[900]
  },
  title: {
    textTransform: 'uppercase'
  },
  buttonMenu: {
    borderRadius: theme.shape.borderRadius
  }
}));

const Sidebar: React.FC<SidebarProps> = ({
  title,
  onClose,
  onSearch,
  onClearSearch,
  actionButtons = [],
  content,
  enableSearch = false,
  ...rootProps
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [state, setState] = React.useState<'normal' | 'search'>('normal');
  const inputRef = React.useRef();

  const handleMoreOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const toolbarButtons = enableSearch
    ? [
        <IconButton key="search" onClick={() => setState('search')}>
          <SearchIcon />
        </IconButton>,
        ...actionButtons
      ]
    : actionButtons;

  return (
    <Box display="flex" flexDirection="column" {...rootProps}>
      <Toolbar
        className={clsx({
          [classes.toolbar]: true,
          [classes.searchToolbar]: state === 'search'
        })}
        disableGutters
      >
        <Grid container spacing={2} alignItems="center" wrap="nowrap">
          <Grid item xs="auto">
            {state === 'search' ? (
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  onClearSearch && onClearSearch();
                  setState('normal');
                }}
              >
                <BackIcon color="inherit" />
              </IconButton>
            ) : (
              <IconButton edge="start" onClick={() => onClose()}>
                <CloseIcon />
              </IconButton>
            )}
          </Grid>
          {state === 'search' ? (
            <Grid item style={{ flex: '1 1 0%', overflow: 'hidden' }}>
              <TextField
                type="search"
                id="search"
                placeholder="SEARCH"
                autoComplete="false"
                inputRef={inputRef}
                onChange={(e) => onSearch && onSearch(e.target.value)}
                fullWidth
                InputProps={{
                  className: classes.searchInput,
                  disableUnderline: true,
                  autoFocus: true
                }}
              />
            </Grid>
          ) : (
            <React.Fragment>
              <Grid item style={{ flex: '1 1 0%', overflow: 'hidden' }}>
                <Typography className={classes.title} variant="h5" noWrap>
                  {title}
                </Typography>
              </Grid>
              {toolbarButtons.length === 1 && (
                <Grid item xs="auto">
                  {React.cloneElement(toolbarButtons[0], {
                    edge: 'end'
                  })}
                </Grid>
              )}
              {toolbarButtons.length > 1 && (
                <Grid item xs="auto">
                  <IconButton
                    edge="end"
                    aria-haspopup="true"
                    onClick={handleMoreOpen}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Popover
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                    classes={{
                      paper: classes.buttonMenu
                    }}
                    onClose={handleMoreClose}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      onClick={handleMoreClose}
                    >
                      {toolbarButtons.map((item) =>
                        React.cloneElement(item, {
                          style: { borderRadius: 0 }
                        })
                      )}
                    </Box>
                  </Popover>
                </Grid>
              )}
            </React.Fragment>
          )}
        </Grid>
      </Toolbar>
      <Divider
        className={clsx({ [classes.searchDivider]: state === 'search' })}
      />
      <Box
        flex={1}
        minHeight={0}
        display="flex"
        flexDirection="column"
        overflow="auto"
      >
        {content}
      </Box>
    </Box>
  );
};

export default Sidebar;
